// ======================================================================
// 99-blocks - Small Intro
// ======================================================================

.block-smallintro {
  padding: rem(0) rem(0px) rem(80px) rem(0px);
  @include type-free(32px, 0px, 0px, 40px, true);

  @include media(">medium") {
    width: 100%;
    max-width: rem(924px);
  }

  @include media("<medium") {
    padding: rem(30px) rem(25px) rem(40px) rem(25px);
    @include type-free(32px, 0px, 0px, 38px, true);
  }
}
