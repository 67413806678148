// ======================================================================
// 06-components -Project Nav
// ======================================================================

.project-np {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: rem(24px);
  padding: 0 rem(25px) rem(60px);

  @include media(">medium") {
    grid-template-columns: 1fr 1fr 1fr;
    margin: rem(20px) 0 rem(140px);
  }

  a {
    color: $cerulean;
    @include type-free(18px, 0px, 0px, 24px, true);
    font-family: "Apercu";
    text-decoration: none;
  }
  @include media(">medium") {
    .project-np-middle {
      text-align: center;
    }

    .project-np-right {
      text-align: right;
    }
  }
}
