// ======================================================================
// Slate Colors | hello@hashandsalt.com
// ======================================================================

$background: #f8f7f3;
// Basic
$asphalt: #222222;
$elephant: #aeaeae;
$khaki: #fdfbf5;
$white: #fff;

// Pallette
$deepblue: #1b1454;
$cerulean: #0875c2;
$tomato: #da2f00;
$bark: #7e3e1a;
$peach: #f4c3b4;
$seafoam: #b8d9c6;
$sand: #f0e1d1;
$periwinkle: #f4f7ff;
$stone: #e9ebf2;

:root {
  --background: #f8f7f3;
  // Basic
  --asphalt: #222222;
  --elephant: #aeaeae;
  --khaki: #fdfbf5;
  --white: #fff;

  // Pallette
  --deepblue: #1b1454;
  --cerulean: #0875c2;
  --tomato: #da2f00;
  --bark: #7e3e1a;
  --peach: #f4c3b4;
  --seafoam: #b8d9c6;
  --sand: #f0e1d1;
  --periwinkle: #f4f7ff;
  --stone: #e9ebf2;
}

[data-color="deepblue"] {
  background-color: #1b1454;
}
[data-color="cerulean"] {
  background-color: #0875c2;
}
[data-color="tomato"] {
  background-color: #da2f00;
}
[data-color="bark"] {
  background-color: #7e3e1a;
}
[data-color="peach"] {
  background-color: #f4c3b4;
}
[data-color="seafoam"] {
  background-color: #b8d9c6;
}
[data-color="sand"] {
  background-color: #f0e1d1;
}
[data-color="periwinkle"] {
  background-color: #f4f7ff;
}
[data-color="stone"] {
  background-color: #e9ebf2;
}

[data-textcolor="black"] {
  color: $asphalt !important;
}

[data-textcolor="white"] {
  color: $white !important;
}

[data-textcolor="deepblue"] {
  color: #1b1454 !important;
}
[data-textcolor="cerulean"] {
  color: #0875c2;
}
[data-textcolor="tomato"] {
  color: #da2f00;
}
[data-textcolor="bark"] {
  color: #7e3e1a;
}
[data-textcolor="peach"] {
  color: #f4c3b4;
}
[data-textcolor="seafoam"] {
  color: #b8d9c6;
}
[data-textcolor="sand"] {
  color: #f0e1d1;
}
[data-textcolor="periwinkle"] {
  color: #f4f7ff;
}
[data-textcolor="stone"] {
  color: #e9ebf2;
}

