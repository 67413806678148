// ======================================================================
// 99-blocks - Intro
// ======================================================================

.block-intro {
  padding: rem(281px) rem(0px) rem(160px) rem(0px);
  @include type-free(40px, 0px, 0px, 48px, true);

  @include media(">medium") {
    width: 100%;
    max-width: rem(820px);
  }

  @include media("<medium") {
    padding: rem(140px) rem(25px) rem(160px) rem(25px);
    @include type-free(32px, 0px, 0px, 38px, true);
  }
}
