// ======================================================================
// 05-objects - Container
// ======================================================================

.container {
  @include container;
  padding: 0 rem(25px);

  main & {
    padding: 0 0 0 0;

    @include media(">medium") {
      padding: 0 rem(25px);
    }
  }

  &.error-page {
    padding: rem(120px) rem(25px) 0 rem(25px);
    text-align: center;
    h1 {
      font-family: $tiempos;
    }
    @include media("<medium") {
      padding: rem(60px) rem(25px) 0 rem(25px);
    }

    a {
      color: $cerulean;
      text-decoration: underline;
    }
  }
}

.breakout {
  background-color: $seafoam;
  max-width: 100vw;
  // padding: 0 rem(25px);
  @include media(">medium") {
    max-width: 1408px;
    margin: auto calc(50% - 50vw + 52px);
    transform: translateX(calc(50vw - 50% - 52px));
  }
}

.breakout-wide {
  @include media(">medium") {
    max-width: 100vw;
    margin: auto calc(50% - 50vw + 52px);

    transform: translateX(calc(50vw - 50% - 52px));
  }
}
