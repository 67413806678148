// ======================================================================
// 99-blocks - How Work
// ======================================================================

.block-how-i-work {
  margin-top: rem(16px);
  margin-bottom: rem(120px);
  @include media("<medium") {
    padding: 0 rem(25px);
    margin-bottom: rem(60px);
  }
  .h3,
  h3 {
    // @include type-modular(epsilon, 0px, 24px );
    @include type-free(24px, 24px, 24px, 29.83px, true);
  }

  img {
    width: auto;
    height: auto;
  }
}
