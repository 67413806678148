// ======================================================================
// 99-blocks - CTA
// ======================================================================

.block-CTA {
  text-align: center;
  margin-bottom: rem(125px);
  p {
    @include type-free(24px, 0, 9px, 29px, true);
  }
}
