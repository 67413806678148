// ======================================================================
// 06-components - Forms
// ======================================================================

.form {
  @include form-complete;

  .input-text {
    border-radius: 4px;
    font-family: $apercu;

    ::placeholder {
      color: $bark;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $bark;
    }

    ::-ms-input-placeholder {
      color: $bark;
    }
  }
}

.form-row {
  display: grid;
  row-gap: rem(26px);
  @include media(">medium") {
    grid-template-columns: rem(410px) auto;
    column-gap: rem(40px);
    align-items: center;
  }
}

.honeypot {
  position: absolute;
  left: -9999px;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.newsletter {
  .alert {
    padding: rem(8px) rem(17px);

    p {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .alert.success {
    background-color: $seafoam;

    p {
      color: darken($seafoam, 40%);
    }
  }

  .alert.error {
    background-color: $peach;

    p {
      color: $tomato;
      text-align: left;
    }
  }
}

// .form-error-hint {
//   font-style: italic;
//   color: $tomato;
// }
