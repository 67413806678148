// ======================================================================
// 99-blocks - Feature Image
// ======================================================================

.featureimage {
  background: linear-gradient(
    31.21deg,
    rgba(8, 129, 171, 0.1) 42.77%,
    rgba(230, 182, 110, 0.1) 86.48%
  );
  border: 1px solid #aeaeae;
  max-width: 100vw;
  margin-top: 48px;
  margin-bottom: 48px;
  line-height: 0;
  // padding: rem(66px) rem(25px) rem(90px);
  // padding: 0 rem(25px);
  @include media(">medium") {
    max-width: 1120px;
    margin: auto calc(50% - 50vw + 52px);
    transform: translateX(calc(50vw - 50% - 52px));
    margin-top: rem(100px);
    margin-bottom: rem(48px);
  }

  figure {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;


    &.contain {
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
      }
    }


    &.cover {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

  }
}
