// ======================================================================
// 06-components - Grid
// ======================================================================

.grid {
  @include media(">medium") {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: rem(56px);
    row-gap: 0;
  }
}

.column {
  grid-column: span var(--span);
}
