// ======================================================================
// 99-blocks - Work with me (Panel Block)
// ======================================================================

.block-work-with-me {
  padding: rem(52px) rem(44px);
  margin-bottom: rem(40px);
  @include media("<medium") {
    padding: rem(52px) rem(25px);
    margin-bottom: 0;
  }

  .h3,
  h3 {
    // @include type-modular(epsilon, 0px, 24px );
    @include type-free(24px, 0px, 24px, 30px, true);
  }
}

#f03cf27b-3a53-4ee0-a3ea-0137b336f4ad .block-work-with-me {
  margin-bottom: rem(40px);
}

.block-work-with-me-details,
.block-work-with-me-pricing {
  background-image: url("/assets/images/rule.png");
  background-repeat: no-repeat;
}

.block-work-with-me-details {
  background-position: left top;
  padding: rem(24px) 0;
  font-family: "Apercu";
  p:last-of-type {
    margin-bottom: 0;
  }
}

.block-work-with-me-pricing {
  background-position: left top;
  padding: rem(24px) 0 0 0;
  font-style: italic;
  p:last-of-type {
    margin-bottom: 0;
  }
}
