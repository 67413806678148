// ======================================================================
// 04-elements - Media
// ======================================================================

figure {
  padding: 0;
  margin: 0;
}

figcaption {
  @include type-free(16px, 0, 24px, 28px, true);
  color: $asphalt;
  font-style: italic;
  text-align: center;
}

img {
  width: 100%;
  height: auto;
}
