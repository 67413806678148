// ======================================================================
// 05-objects - Logo
// ======================================================================

.logo {
  @include container;
  padding: 0 rem(25px);
  // margin-bottom: rem(100px);

  a {
    text-transform: uppercase;
    @include type-free(20px, 0px, 0px, 24px, true);
    font-family: $apercu;
    letter-spacing: 1px;
    text-decoration: none;
    border-bottom: 2px solid $asphalt;
    margin-top: rem(21px);
    @include media("<medium") {
      margin-top: rem(4px);
    }
    display: inline-block;
    &:hover {
      border-bottom: 2px solid $tomato;
      transition: border-bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
