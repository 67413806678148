// ======================================================================
// 99-blocks - Page Head Box
// ======================================================================

.block-page-head-box {
  padding: rem(60px) 0;
  margin-top: rem(100px);
  margin-bottom: rem(120px);

  @include media("<medium") {
    margin-bottom: rem(75px);
  }

  .h1,
  h1 {
    // @include type-modular(delta, 0px, 48px);
    @include type-free(40px, 0px, 40px, 49.72px, true);
    font-weight: 700;
    max-width: rem(720px);
  }
}

.block-page-head-box-inner {
  @include container();
  padding: 0 rem(25px);
  // @include media("<1269px") {
  //   padding: 0 rem(60px);
  // }
  @include media("<medium") {
    padding: 0 rem(25px);
  }
}

.block-page-head-box {
  .block-content {
    p {
      max-width: rem(720px);
    }
    p:first-of-type {
      font-family: $tiempos;
      @include type-free(32px, 0px, 24px, 48px, true);
    }
  }
}
