// ======================================================================
// 04-elements - Body
// ======================================================================

body {
  padding: 0;
  margin: 0;

  @include media(">medium") {
    padding: 80px;
  }
  height: 100%;
  width: 100%;
  display: block;

  &.inverted {
    background-color: $background;
  }
}

.wrapper {
  display: grid;
  grid-template-rows: 61px 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%;
  background-color: $background;

  .inverted & {
    background-color: white;
  }
}
