// ======================================================================
// 06-components -Essay
// ======================================================================

.essay {
  h1 {
    @include type-free(40px, 115px, 100px, 48px, true);
    text-align: center;
    font-family: $tiempos;
    font-weight: 500;
  }

  header {
    overflow: hidden;
  }

  .block-side-heading-with-text {
    max-width: rem(728px);
    margin: 0 auto rem(76px) auto;

    column-gap: rem(50px);

    h3 {
      margin-top: 0;
    }
  }

  .blocks {
    p,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-left: rem(25px);
      margin-right: rem(25px);
      max-width: rem(728px);

      @include media(">1030px") {
        margin-left: rem(196px);
        margin-right: rem(196px);
      }
    }

    table {
      p {
        max-width: auto;
        margin: 0 0 0 0;
      }
    }
    figure {
      margin: 0 0 rem(40px) 0;
    }

    a {
      color: $cerulean;
      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      margin-left: rem(25px);
      @include media(">medium") {
        margin-left: rem(196px);
      }

      list-style: none;

      li::before {
        content: "\2022";
        color: $cerulean;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }

    ol {
      margin-left: rem(25px);
      @include media(">medium") {
        margin-left: rem(196px);
      }
    }
  }
  .pubdate {
    text-align: center;
    display: block;
    margin: rem(80px) auto rem(80px);
    color: $bark;
    font-family: $apercu;
  }

  .essay-foot {
    width: 100%;
    max-width: 368px;
    display: block;
    margin: rem(80px) auto rem(80px);
  }

  .lede {
    margin-bottom: rem(56px);
    font-style: italic;
    a {
      color: $cerulean;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.essay-np,
.essay-fr {
  @include media("<medium") {
    padding: 0 rem(25px) rem(56px);
  }
  width: 100%;
  max-width: rem(728px);

  // @include media("<medium") {
  //   margin: 0 rem(25px) rem(56px) rem(25px);
  // }

  h2 {
    margin-left: 0;
    margin-right: 0;
    max-width: auto;

    @include media(">medium") {
      margin-left: 0;
      margin-right: 0;
    }
  }
  a {
    color: $cerulean;
    &:hover {
      text-decoration: underline;
    }
  }
}

.essay-np {
  @include media(">medium") {
    margin: 0 auto rem(56px) auto;
    // margin: 0 rem(196px) rem(56px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.essay-fr {
  border-top: 1px solid $seafoam;
  padding-top: 32px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
