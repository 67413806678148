// ======================================================================
// 99-blocks - Divider
// ======================================================================
.block-divider {
  margin: rem(120px) 0;
  img {
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }

}