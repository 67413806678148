// ======================================================================
// 06-components - Footer
// ======================================================================

.site-footer {
  background-color: $sand;
  padding: rem(60px) 0;
  font-family: $apercu;
  color: $deepblue;

  a {
    color: $tomato;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    @include type-free(18px, 0, 16px, 22px, true);
  }

  .linklist {
    margin: 0;
    padding: 0;
    list-style: none;
    @include media("<medium") {
      margin: rem(44px) 0 rem(40px) 0;
    }
  }
}

.footer-content {
  @include media(">medium") {
    display: grid;
    grid-template-columns: 1fr 111px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "fl fl fr"
      "copy copy copy";
  }
}

 
.footer-left {
  grid-area: fl;
  p {
    max-width: rem(355px);
  }
}
.footer-right {
  grid-area: fr;
}


.copy {
  grid-area: copy;
  p {
    margin-bottom: 0;
  }
}
