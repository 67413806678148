// ======================================================================
// 99-blocks - Recent Projects
// ======================================================================

.recent-projects {
  background-color: $periwinkle;
  padding-top: rem(120px);

  h2 {
    @include media("<medium") {
      padding: 0 rem(25px);
    }
  }

  .arrow-butt {
    margin-bottom: rem(120px);
  }
}

.item-grid {
  margin-bottom: rem(65px);
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: rem(40px);
  grid-column-gap: rem(57px);

  @include media("<medium") {
    margin-bottom: rem(48px);
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0 rem(25px);
  }

  figcaption {
    padding: rem(8px) 0;
    line-height: 1.5;
    font-family: "Apercu";
    text-align: left;
    font-style: normal;
    a {
      color: $cerulean;
      @include type-free(24px, 0, 12px, 30px, true);
      white-space: nowrap;
    }
  }

  p {
    @include type-free(18px, 0, 0, 22px, true);
  }
  a {
    width: 100%;
    line-height: 0;
  }
}

.item-container {
  display: block;

  img {
    &.cover {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.item {
  grid-column: span 1;

  &:first-child {
    grid-column: span 2;
    grid-row: span 2;

    @include media("<medium") {
      grid-column: span 1;
    }
  }

  figure {
    display: grid;
    // grid-template-rows: 1fr 140px;
    grid-template-rows: 1fr auto;

    height: 100%;

    .item-image {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 1px solid #aeaeae;
      // padding: rem(25px);

      // @include media("<medium") {
      //   padding: rem(8px);
      // }
        background-color: #fff;
      background: radial-gradient(
          62.5% 105.93% at 19.25% 62.29%,
          rgba(255, 233, 132, 0.2) 0%,
          rgba(238, 238, 238, 0.112) 100%
        ),
        radial-gradient(
          73.88% 212.22% at 17.25% 23.31%,
          #eeeeee 0%,
          rgba(215, 228, 218, 0.72) 100%
        );
    }

    @include media("<965px") {
      grid-template-rows: 1fr auto;
    }
  }
}
