@charset "UTF-8";
:root {
  --background: #f8f7f3;
  --asphalt: #222222;
  --elephant: #aeaeae;
  --khaki: #fdfbf5;
  --white: #fff;
  --deepblue: #1b1454;
  --cerulean: #0875c2;
  --tomato: #da2f00;
  --bark: #7e3e1a;
  --peach: #f4c3b4;
  --seafoam: #b8d9c6;
  --sand: #f0e1d1;
  --periwinkle: #f4f7ff;
  --stone: #e9ebf2;
}

[data-color=deepblue] {
  background-color: #1b1454;
}

[data-color=cerulean] {
  background-color: #0875c2;
}

[data-color=tomato] {
  background-color: #da2f00;
}

[data-color=bark] {
  background-color: #7e3e1a;
}

[data-color=peach] {
  background-color: #f4c3b4;
}

[data-color=seafoam] {
  background-color: #b8d9c6;
}

[data-color=sand] {
  background-color: #f0e1d1;
}

[data-color=periwinkle] {
  background-color: #f4f7ff;
}

[data-color=stone] {
  background-color: #e9ebf2;
}

[data-textcolor=black] {
  color: #222222 !important;
}

[data-textcolor=white] {
  color: #fff !important;
}

[data-textcolor=deepblue] {
  color: #1b1454 !important;
}

[data-textcolor=cerulean] {
  color: #0875c2;
}

[data-textcolor=tomato] {
  color: #da2f00;
}

[data-textcolor=bark] {
  color: #7e3e1a;
}

[data-textcolor=peach] {
  color: #f4c3b4;
}

[data-textcolor=seafoam] {
  color: #b8d9c6;
}

[data-textcolor=sand] {
  color: #f0e1d1;
}

[data-textcolor=periwinkle] {
  color: #f4f7ff;
}

[data-textcolor=stone] {
  color: #e9ebf2;
}

@font-face {
  font-family: "Apercu";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("/assets/fonts/apercu_regular.woff2") format("woff2"), url("/assets/fonts/apercu_regular.woff") format("woff");
}
@font-face {
  font-family: "Apercu";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("/assets/fonts/apercu_regular_italic.woff2") format("woff2"), url("/assets/fonts/apercu_regular_italic.woff") format("woff");
}
@font-face {
  font-family: "Tiempos";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("/assets/fonts/TiemposTextWeb-Regular.woff") format("woff");
}
@font-face {
  font-family: "Tiempos";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("/assets/fonts/TiemposTextWeb-RegularItalic.woff") format("woff");
}
html {
  font-size: 112.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  color: #222222;
  font-family: "Tiempos", Georgia, serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

.h1,
h1 {
  font-size: 2.2222222222rem;
  line-height: 1.243;
  margin-top: 1.3333333333rem;
  margin-bottom: 1.3333333333rem;
}

.h2,
h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 1.3333333333rem;
  margin-bottom: 1.3333333333rem;
}

.h3,
h3 {
  font-size: 1.3333333333rem;
  line-height: 1.2429166667;
  margin-top: 4.1111111111rem;
  margin-bottom: 1.3333333333rem;
}

.h4,
.h5,
h4,
h5 {
  font-size: 1.1111111111rem;
  line-height: 1.243;
  margin-top: 1.3333333333rem;
  margin-bottom: 0;
}

.h1,
.h2,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  color: #222222;
  font-family: "Apercu", Arial, sans-serif;
  font-weight: 700;
}

p {
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
}

ol,
ul {
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
}
ol ol,
ul ol {
  margin-bottom: 0;
  margin-top: 0;
}
ol ul,
ul ul {
  margin-bottom: 0;
  margin-top: 0;
}

dl {
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
}
dl dt {
  font-weight: 600;
}
dl dd {
  font-style: italic;
}

blockquote {
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
  font-style: italic;
}

blockquote > p {
  padding: 0;
}

a {
  color: #222222;
  text-decoration: none;
}
a:hover {
  color: #222222;
  text-decoration: none;
}
a:active, a:focus {
  outline: none;
  color: #222222;
}

hr {
  border: 0;
  border-top: 1px solid #222222;
  display: block;
  height: 1px;
  padding: 0;
  margin-bottom: 1.3333333333rem;
}

sub,
sup {
  position: relative;
  top: -0.4rem;
  vertical-align: baseline;
}

sub {
  top: 0.4rem;
}

b {
  line-height: 1;
}

small {
  line-height: 1;
}

strong {
  font-weight: 700;
}

cite,
dfn,
em,
q {
  line-height: 1.5;
}

code {
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
}

kbd {
  margin-bottom: 1px;
  padding: 0;
  position: relative;
  top: -1px;
}

pre {
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
  padding: 0.6666666667rem;
  border-radius: 0.2222222222rem;
  background-color: #3c3c3c;
  color: #eee;
  font-weight: 700;
  font-family: Monaco, "Lucida Sans Typewriter", Consolas, "Courier New", monospace;
  white-space: pre;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: pre\9 ;
}

@media (max-width: 800px) {
  .page-title {
    padding: 0 1.3888888889rem;
  }
}
.page-title .h1,
.page-title h1 {
  font-size: 2.2222222222rem;
  line-height: 1.25;
  margin-top: 6.8333333333rem;
  margin-bottom: 5.5555555556rem;
}
@media (max-width: 800px) {
  .page-title .h1,
.page-title h1 {
    font-size: 2.2222222222rem;
    line-height: 1.25;
    margin-top: 3.3333333333rem;
    margin-bottom: 2.7777777778rem;
  }
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
html *,
html *:after,
html *:before {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: block;
}
@media (min-width: 800px) {
  body {
    padding: 80px;
  }
}
body.inverted {
  background-color: #f8f7f3;
}

.wrapper {
  display: grid;
  grid-template-rows: 61px 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%;
  background-color: #f8f7f3;
}
.inverted .wrapper {
  background-color: white;
}

figure {
  padding: 0;
  margin: 0;
}

figcaption {
  font-size: 0.8888888889rem;
  line-height: 1.75;
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
  color: #222222;
  font-style: italic;
  text-align: center;
}

img {
  width: 100%;
  height: auto;
}

.btn {
  margin: 0 0 1.3333333333rem 0;
  padding: 0 0.4444444444rem;
  line-height: 2.4444444444rem;
  font-size: 1rem;
  height: 2.4444444444rem;
  border-radius: 0;
  font-family: inherit;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  box-sizing: content-box;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  background: #222222;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #aeaeae;
  box-sizing: border-box;
}
.btn:hover {
  color: #fff;
  text-decoration: none;
  background: black;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #222222;
}
.btn:focus {
  color: #fff;
  text-decoration: none;
  background: black;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #222222;
}
.btn:active {
  color: #fff;
  text-decoration: none;
  background: #090909;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
}
.btn:disabled {
  color: #fff;
  text-decoration: none;
  background: #aeaeae;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #7b7b7b;
}

.arrow-butt {
  color: #da2f00;
  font-size: 1.3333333333rem;
  line-height: 1.2083333333;
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-decoration: underline;
  position: relative;
  display: inline-block;
  font-family: "Tiempos", Georgia, serif;
}
.arrow-butt:hover {
  color: #f4c3b4;
  text-decoration: underline;
}
.arrow-butt:hover:after {
  background-image: url("/assets/images/arrow-peach.svg");
}
.arrow-butt:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("/assets/images/arrow-tomato.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  right: -44px;
  top: 0;
}

button.arrow-butt {
  border: none;
  background: transparent;
}
button.arrow-butt:hover {
  cursor: pointer;
}

.arrow-butt-blue-left,
.arrow-butt-blue-right {
  color: #0875c2;
  font-size: 1rem;
  line-height: 1.3333333333;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Apercu";
  text-decoration: none;
  position: relative;
  display: inline-block;
}
.arrow-butt-blue-left:hover,
.arrow-butt-blue-right:hover {
  text-decoration: underline;
  color: #0875c2;
}
.arrow-butt-blue-left:after, .arrow-butt-blue-left:before,
.arrow-butt-blue-right:after,
.arrow-butt-blue-right:before {
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
}

.arrow-butt-blue-right {
  margin-right: 44px;
}
.arrow-butt-blue-right:after {
  background-image: url("/assets/images/right-blue-arrow.svg");
  right: -44px;
}

.arrow-butt-blue-left {
  margin-left: 44px;
}
.arrow-butt-blue-left:before {
  background-image: url("/assets/images/left-blue-arrow.svg");
  left: -44px;
}

.menu-butt {
  background: transparent;
  padding: 0;
  border: none;
  width: 16px;
  height: 16px;
}
.menu-butt svg {
  width: 100%;
  height: 100%;
}
.menu-butt .cross-icon {
  display: none;
}
.menu-butt:hover {
  cursor: pointer;
}

.menu-open .menu-icon {
  display: none;
}
.menu-open .cross-icon {
  display: block;
}

table {
  width: 100%;
  max-width: 1120px;
}

.logo {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.3888888889rem;
}
.logo:after {
  content: "";
  display: table;
  clear: both;
}
.logo a {
  text-transform: uppercase;
  font-size: 1.1111111111rem;
  line-height: 1.2;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Apercu", Arial, sans-serif;
  letter-spacing: 1px;
  text-decoration: none;
  border-bottom: 2px solid #222222;
  margin-top: 1.1666666667rem;
  display: inline-block;
}
@media (max-width: 800px) {
  .logo a {
    margin-top: 0.2222222222rem;
  }
}
.logo a:hover {
  border-bottom: 2px solid #da2f00;
  transition: border-bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.container {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.3888888889rem;
}
.container:after {
  content: "";
  display: table;
  clear: both;
}
main .container {
  padding: 0 0 0 0;
}
@media (min-width: 800px) {
  main .container {
    padding: 0 1.3888888889rem;
  }
}
.container.error-page {
  padding: 6.6666666667rem 1.3888888889rem 0 1.3888888889rem;
  text-align: center;
}
.container.error-page h1 {
  font-family: "Tiempos", Georgia, serif;
}
@media (max-width: 800px) {
  .container.error-page {
    padding: 3.3333333333rem 1.3888888889rem 0 1.3888888889rem;
  }
}
.container.error-page a {
  color: #0875c2;
  text-decoration: underline;
}

.breakout {
  background-color: #b8d9c6;
  max-width: 100vw;
}
@media (min-width: 800px) {
  .breakout {
    max-width: 1408px;
    margin: auto calc(50% - 50vw + 52px);
    transform: translateX(calc(50vw - 50% - 52px));
  }
}

@media (min-width: 800px) {
  .breakout-wide {
    max-width: 100vw;
    margin: auto calc(50% - 50vw + 52px);
    transform: translateX(calc(50vw - 50% - 52px));
  }
}

.site-footer {
  background-color: #f0e1d1;
  padding: 3.3333333333rem 0;
  font-family: "Apercu", Arial, sans-serif;
  color: #1b1454;
}
.site-footer a {
  color: #da2f00;
}
.site-footer a:hover {
  text-decoration: underline;
}
.site-footer p {
  font-size: 1rem;
  line-height: 1.2222222222;
  margin-top: 0;
  margin-bottom: 0.8888888889rem;
}
.site-footer .linklist {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 800px) {
  .site-footer .linklist {
    margin: 2.4444444444rem 0 2.2222222222rem 0;
  }
}

@media (min-width: 800px) {
  .footer-content {
    display: grid;
    grid-template-columns: 1fr 111px;
    grid-template-rows: auto auto;
    grid-template-areas: "fl fl fr" "copy copy copy";
  }
}

.footer-left {
  grid-area: fl;
}
.footer-left p {
  max-width: 19.7222222222rem;
}

.footer-right {
  grid-area: fr;
}

.copy {
  grid-area: copy;
}
.copy p {
  margin-bottom: 0;
}

.menu {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.3888888889rem;
  font-family: "Apercu", Arial, sans-serif;
}
.menu:after {
  content: "";
  display: table;
  clear: both;
}
@media (max-width: 800px) {
  .menu {
    padding: 1.1666666667rem 1.3888888889rem 0 1.3888888889rem;
  }
}

@media (min-width: 800px) {
  .menu-toggle {
    display: none;
  }
}

.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  z-index: 200;
  position: relative;
}
@media (min-width: 800px) {
  .menu-list {
    margin: -0.7777777778rem 0 0 0;
  }
}
.menu-list li {
  flex-grow: 1;
  text-align: right;
}
.menu-list li.active a {
  color: #da2f00;
}
.menu-list .home-link {
  flex-grow: 0;
  text-align: left;
}
.menu-list a {
  font-size: 1.1111111111rem;
  line-height: 1.2;
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-transform: uppercase;
  display: inline;
  padding: 0 0 0.125rem;
  text-decoration: none;
  letter-spacing: 1px;
  color: #7e3e1a;
}
.menu-list a:hover {
  color: #da2f00;
}

@media (max-width: 800px) {
  li.menu-link {
    display: none;
  }
}

.menu-open li.menu-link,
.menu-open li.home-link,
.menu-open .logo a {
  display: none;
}

.site-menu {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  opacity: 0;
}
.site-menu li.menu-link {
  display: block;
}
.site-menu.site-menu-inactive {
  opacity: 0;
}
.site-menu.site-menu-active {
  display: grid;
  opacity: 1;
}
.site-menu ul {
  list-style: none;
  padding: 0;
  margin: 5.3333333333rem 0 0 1.3888888889rem;
}
.site-menu a {
  font-size: 0.9444444444rem;
  line-height: 1.2352941176;
  margin-top: 0;
  margin-bottom: 1.7777777778rem;
  text-transform: uppercase;
  font-family: "Apercu", Arial, sans-serif;
  display: inline-block;
  color: #7e3e1a;
}
.site-menu a:hover {
  text-decoration: none;
  color: #da2f00;
}
.site-menu a:active, .site-menu a:focus {
  outline: none;
  color: #da2f00;
}

.form {
  margin: 0 0 0 0;
  padding: 0;
}
.form legend {
  margin: 0 0 0 0;
  line-height: 1.5;
}
.form fieldset {
  margin: 0 0 0 0;
  padding: 1.3333333333rem 0 1.3333333333rem 0;
  display: block;
}
.form label,
.form .label {
  margin: 0 0 0 0;
  display: inline-block;
  font-style: inherit;
  color: #222222;
  line-height: 1.5;
}
.form input,
.form select,
.form textarea {
  display: inline-block;
  width: 100%;
}
.form .input-text {
  height: 2.6111111111rem;
  margin: 0 0 0 0;
  font-size: 1rem;
  padding: 0.7222222222rem 0.9444444444rem;
  width: 100%;
  font-family: inherit;
  box-sizing: border-box;
  background-color: #f0e1d1;
  border: 0;
  color: #7e3e1a;
}
.form .input-text:hover {
  outline: none;
  background-color: #f0e1d1;
  border: 0;
  color: #7e3e1a;
}
.form .input-text:focus {
  outline: none;
  background-color: #f0e1d1;
  border: 0;
  color: #7e3e1a;
}
.form .input-text:disabled {
  cursor: disabled;
  background-color: #aeaeae;
  border: 0;
  color: #7e3e1a;
}
.form textarea {
  margin: 0 0 0 0;
  min-height: 8.3333333333rem;
  position: relative;
  overflow: auto;
  outline: none;
  line-height: 1.5;
  box-sizing: border-box;
  resize: vertical;
}
.form [type=search] {
  -webkit-appearance: none;
}
.form [list]::-webkit-calendar-picker-indicator {
  display: none;
}
.form .datalist {
  background-size: 11px 6px;
  background-position: 98% center;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%237e3e1a' d='M.758.57h10l-5 5z' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.form [type=color] {
  height: 2.6111111111rem;
  margin: 0 0 0 0;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0.2222222222rem;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #aeaeae;
}
.form [type=color]:hover {
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #222222;
}
.form [type=color]:focus {
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #222222;
}
.form [type=color]:focus {
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #aeaeae;
}
.form .input-text {
  border-radius: 4px;
  font-family: "Apercu", Arial, sans-serif;
}
.form .input-text ::placeholder {
  color: #7e3e1a;
  opacity: 1;
}
.form .input-text :-ms-input-placeholder {
  color: #7e3e1a;
}
.form .input-text ::-ms-input-placeholder {
  color: #7e3e1a;
}

.form-row {
  display: grid;
  row-gap: 1.4444444444rem;
}
@media (min-width: 800px) {
  .form-row {
    grid-template-columns: 22.7777777778rem auto;
    column-gap: 2.2222222222rem;
    align-items: center;
  }
}

.honeypot {
  position: absolute;
  left: -9999px;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.newsletter .alert {
  padding: 0.4444444444rem 0.9444444444rem;
}
.newsletter .alert p {
  margin-bottom: 0;
  text-align: center;
}
.newsletter .alert.success {
  background-color: #b8d9c6;
}
.newsletter .alert.success p {
  color: #45805e;
}
.newsletter .alert.error {
  background-color: #f4c3b4;
}
.newsletter .alert.error p {
  color: #da2f00;
  text-align: left;
}

@media (min-width: 800px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 3.1111111111rem;
    row-gap: 0;
  }
}

.column {
  grid-column: span var(--span);
}

.essay h1 {
  font-size: 2.2222222222rem;
  line-height: 1.2;
  margin-top: 6.3888888889rem;
  margin-bottom: 5.5555555556rem;
  text-align: center;
  font-family: "Tiempos", Georgia, serif;
  font-weight: 500;
}
.essay header {
  overflow: hidden;
}
.essay .block-side-heading-with-text {
  max-width: 40.4444444444rem;
  margin: 0 auto 4.2222222222rem auto;
  column-gap: 2.7777777778rem;
}
.essay .block-side-heading-with-text h3 {
  margin-top: 0;
}
.essay .blocks p,
.essay .blocks h2,
.essay .blocks h3,
.essay .blocks h4,
.essay .blocks h5,
.essay .blocks h6 {
  margin-left: 1.3888888889rem;
  margin-right: 1.3888888889rem;
  max-width: 40.4444444444rem;
}
@media (min-width: 1030px) {
  .essay .blocks p,
.essay .blocks h2,
.essay .blocks h3,
.essay .blocks h4,
.essay .blocks h5,
.essay .blocks h6 {
    margin-left: 10.8888888889rem;
    margin-right: 10.8888888889rem;
  }
}
.essay .blocks table p {
  max-width: auto;
  margin: 0 0 0 0;
}
.essay .blocks figure {
  margin: 0 0 2.2222222222rem 0;
}
.essay .blocks a {
  color: #0875c2;
}
.essay .blocks a:hover {
  text-decoration: underline;
}
.essay .blocks ul {
  margin-left: 1.3888888889rem;
  list-style: none;
}
@media (min-width: 800px) {
  .essay .blocks ul {
    margin-left: 10.8888888889rem;
  }
}
.essay .blocks ul li::before {
  content: "•";
  color: #0875c2;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.essay .blocks ol {
  margin-left: 1.3888888889rem;
}
@media (min-width: 800px) {
  .essay .blocks ol {
    margin-left: 10.8888888889rem;
  }
}
.essay .pubdate {
  text-align: center;
  display: block;
  margin: 4.4444444444rem auto 4.4444444444rem;
  color: #7e3e1a;
  font-family: "Apercu", Arial, sans-serif;
}
.essay .essay-foot {
  width: 100%;
  max-width: 368px;
  display: block;
  margin: 4.4444444444rem auto 4.4444444444rem;
}
.essay .lede {
  margin-bottom: 3.1111111111rem;
  font-style: italic;
}
.essay .lede a {
  color: #0875c2;
}
.essay .lede a:hover {
  text-decoration: underline;
}

.essay-np,
.essay-fr {
  width: 100%;
  max-width: 40.4444444444rem;
}
@media (max-width: 800px) {
  .essay-np,
.essay-fr {
    padding: 0 1.3888888889rem 3.1111111111rem;
  }
}
.essay-np h2,
.essay-fr h2 {
  margin-left: 0;
  margin-right: 0;
  max-width: auto;
}
@media (min-width: 800px) {
  .essay-np h2,
.essay-fr h2 {
    margin-left: 0;
    margin-right: 0;
  }
}
.essay-np a,
.essay-fr a {
  color: #0875c2;
}
.essay-np a:hover,
.essay-fr a:hover {
  text-decoration: underline;
}

@media (min-width: 800px) {
  .essay-np {
    margin: 0 auto 3.1111111111rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.essay-fr {
  border-top: 1px solid #b8d9c6;
  padding-top: 32px;
}
.essay-fr ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.project-np {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.3333333333rem;
  padding: 0 1.3888888889rem 3.3333333333rem;
}
@media (min-width: 800px) {
  .project-np {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.1111111111rem 0 7.7777777778rem;
  }
}
.project-np a {
  color: #0875c2;
  font-size: 1rem;
  line-height: 1.3333333333;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Apercu";
  text-decoration: none;
}
@media (min-width: 800px) {
  .project-np .project-np-middle {
    text-align: center;
  }
  .project-np .project-np-right {
    text-align: right;
  }
}

.block-intro {
  padding: 15.6111111111rem 0rem 8.8888888889rem 0rem;
  font-size: 2.2222222222rem;
  line-height: 1.2;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
@media (min-width: 800px) {
  .block-intro {
    width: 100%;
    max-width: 45.5555555556rem;
  }
}
@media (max-width: 800px) {
  .block-intro {
    padding: 7.7777777778rem 1.3888888889rem 8.8888888889rem 1.3888888889rem;
    font-size: 1.7777777778rem;
    line-height: 1.1875;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

.block-smallintro {
  padding: 0 0rem 4.4444444444rem 0rem;
  font-size: 1.7777777778rem;
  line-height: 1.25;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
@media (min-width: 800px) {
  .block-smallintro {
    width: 100%;
    max-width: 51.3333333333rem;
  }
}
@media (max-width: 800px) {
  .block-smallintro {
    padding: 1.6666666667rem 1.3888888889rem 2.2222222222rem 1.3888888889rem;
    font-size: 1.7777777778rem;
    line-height: 1.1875;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

.block-about {
  padding: 3.3333333333rem 0 3.3333333333rem 0;
  margin-top: 5.5555555556rem;
}
.block-about a {
  color: #0875c2;
}
.block-about a:hover {
  text-decoration: underline;
}
.block-about .h1,
.block-about h1 {
  font-size: 2.2222222222rem;
  line-height: 1.243;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
  font-weight: 700;
}

.block-about-inner {
  display: grid;
  grid-template-columns: 29.5555555556rem 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "bh bh" "bc bi";
}
@media (max-width: 960px) {
  .block-about-inner {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    column-gap: 0;
    grid-template-areas: "bh bh" "bi bi" "bc bc";
  }
}
@media (min-width: 1485px) {
  .block-about-inner {
    padding: 0 0 0 8.0555555556rem;
    column-gap: 3.4444444444rem;
  }
}
@media (max-width: 1485px) {
  .block-about-inner {
    grid-template-columns: 60% 40%;
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 3.3333333333rem;
  }
  .block-about-inner:after {
    content: "";
    display: table;
    clear: both;
  }
}
@media (max-width: 800px) {
  .block-about-inner {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 1.3888888889rem;
  }
  .block-about-inner:after {
    content: "";
    display: table;
    clear: both;
  }
}
@media (min-width: 960px) and (max-width: 1485px) {
  .block-about-inner {
    column-gap: 1.7222222222rem;
  }
}
.block-about-inner .block-header {
  grid-area: bh;
}
.block-about-inner .block-content {
  grid-area: bc;
}
.block-about-inner .block-image {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-area: bi;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.block-about-inner .block-image figure {
  width: 100%;
  margin-bottom: 1.3333333333rem;
}

.wwm {
  background-color: #1b1454;
  padding: 3.3333333333rem 0;
}
@media (min-width: 800px) {
  .wwm {
    padding: 6.6666666667rem 0;
  }
}
.wwm h2,
.wwm p {
  color: #fff;
}
.wwm h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 0rem;
  margin-bottom: 2.2222222222rem;
}

@media (min-width: 800px) {
  .wwm-content {
    display: grid;
    grid-template-columns: 1fr 111px;
    grid-template-rows: auto auto;
    column-gap: 1.3333333333rem;
    grid-template-areas: "wwml wwml wwmr" "wwml wwml wwmr";
  }
}

.wwm-left {
  grid-area: wwml;
}
.wwm-left p {
  max-width: 40.2777777778rem;
}

.wwm-right {
  grid-area: wwmr;
}
.wwm-right img {
  max-width: 6.1666666667rem;
  display: block;
}
@media (max-width: 800px) {
  .wwm-right img {
    margin: 3.2222222222rem 0 0 0;
  }
}
@media (min-width: 800px) {
  .wwm-right {
    display: grid;
    align-items: center;
    justify-content: flex-end;
  }
}

.newsletter {
  padding: 4rem 1.3888888889rem;
}
@media (min-width: 800px) {
  .newsletter {
    padding: 6.6666666667rem 1.3888888889rem;
  }
}
.newsletter h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}
.newsletter p {
  margin-bottom: 2.2222222222rem;
}
.newsletter main {
  max-width: 40.4444444444rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.newsletter main:after {
  content: "";
  display: table;
  clear: both;
}

.block-qoute {
  margin-bottom: 5.5555555556rem;
}

.block-qoute-inner {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 6.6666666667rem 0;
  text-align: center;
  font-family: "Tiempos", Georgia, serif;
}
.block-qoute-inner:after {
  content: "";
  display: table;
  clear: both;
}
.block-qoute-inner blockquote {
  font-size: 1.7777777778rem;
  line-height: 1.25;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Tiempos", Georgia, serif;
}
@media (max-width: 800px) {
  .block-qoute-inner blockquote {
    font-size: 1.3333333333rem;
    line-height: 1.2083333333;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
.block-qoute-inner footer {
  margin-top: 1.3333333333rem;
  font-family: "Apercu", Arial, sans-serif;
  font-style: normal;
  font-size: 0.8333333333rem;
  line-height: 1.2666666667;
  margin-top: 1.3333333333rem;
  margin-bottom: 0rem;
}
@media (max-width: 800px) {
  .block-qoute-inner footer {
    font-size: 0.8333333333rem;
    line-height: 1.2;
    margin-top: 1.3333333333rem;
    margin-bottom: 0rem;
  }
}

.block-simplequote {
  font-style: italic;
}

.block-page-head-box {
  padding: 3.3333333333rem 0;
  margin-top: 5.5555555556rem;
  margin-bottom: 6.6666666667rem;
}
@media (max-width: 800px) {
  .block-page-head-box {
    margin-bottom: 4.1666666667rem;
  }
}
.block-page-head-box .h1,
.block-page-head-box h1 {
  font-size: 2.2222222222rem;
  line-height: 1.243;
  margin-top: 0rem;
  margin-bottom: 2.2222222222rem;
  font-weight: 700;
  max-width: 40rem;
}

.block-page-head-box-inner {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.3888888889rem;
}
.block-page-head-box-inner:after {
  content: "";
  display: table;
  clear: both;
}
@media (max-width: 800px) {
  .block-page-head-box-inner {
    padding: 0 1.3888888889rem;
  }
}

.block-page-head-box .block-content p {
  max-width: 40rem;
}
.block-page-head-box .block-content p:first-of-type {
  font-family: "Tiempos", Georgia, serif;
  font-size: 1.7777777778rem;
  line-height: 1.5;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}

.block-what-i-do {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 0 6.6666666667rem 0;
}
.block-what-i-do:after {
  content: "";
  display: table;
  clear: both;
}
@media (max-width: 800px) {
  .block-what-i-do {
    padding: 2.2222222222rem 1.3888888889rem;
  }
}
.block-what-i-do .h2,
.block-what-i-do h2 {
  font-size: 1.7777777778rem;
  line-height: 1.25;
  margin-top: 0rem;
  margin-bottom: 2.2222222222rem;
}
.block-what-i-do ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
@media (min-width: 800px) {
  .block-what-i-do ul {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3.1111111111rem;
  }
}

.block-side-heading-with-text {
  margin-bottom: 5.5555555556rem;
}
@media (max-width: 800px) {
  .block-side-heading-with-text {
    padding: 0 1.3888888889rem;
  }
}
@media (min-width: 960px) {
  .block-side-heading-with-text {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.3333333333rem;
  }
}
.block-side-heading-with-text .h2,
.block-side-heading-with-text h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}
.block-side-heading-with-text a {
  color: #0875c2;
}
.block-side-heading-with-text a:hover {
  text-decoration: underline;
}

.block-side-heading-with-list {
  margin-bottom: 5.5555555556rem;
}
@media (max-width: 800px) {
  .block-side-heading-with-list {
    padding: 0 1.3888888889rem;
  }
}
@media (min-width: 1080px) {
  .block-side-heading-with-list {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.3333333333rem;
  }
}
.block-side-heading-with-list .h2,
.block-side-heading-with-list h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}
.block-side-heading-with-list a {
  color: #0875c2;
}
.block-side-heading-with-list a:hover {
  text-decoration: underline;
}
.block-side-heading-with-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
@media (min-width: 800px) {
  .block-side-heading-with-list ul {
    column-count: 2;
  }
}

.block-divider {
  margin: 6.6666666667rem 0;
}
.block-divider img {
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
}

.block-how-i-work {
  margin-top: 0.8888888889rem;
  margin-bottom: 6.6666666667rem;
}
@media (max-width: 800px) {
  .block-how-i-work {
    padding: 0 1.3888888889rem;
    margin-bottom: 3.3333333333rem;
  }
}
.block-how-i-work .h3,
.block-how-i-work h3 {
  font-size: 1.3333333333rem;
  line-height: 1.2429166667;
  margin-top: 1.3333333333rem;
  margin-bottom: 1.3333333333rem;
}
.block-how-i-work img {
  width: auto;
  height: auto;
}

@media (max-width: 800px) {
  .block-heading {
    padding: 0 1.3888888889rem;
  }
}

.block-work-with-me {
  padding: 2.8888888889rem 2.4444444444rem;
  margin-bottom: 2.2222222222rem;
}
@media (max-width: 800px) {
  .block-work-with-me {
    padding: 2.8888888889rem 1.3888888889rem;
    margin-bottom: 0;
  }
}
.block-work-with-me .h3,
.block-work-with-me h3 {
  font-size: 1.3333333333rem;
  line-height: 1.25;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}

#f03cf27b-3a53-4ee0-a3ea-0137b336f4ad .block-work-with-me {
  margin-bottom: 2.2222222222rem;
}

.block-work-with-me-details,
.block-work-with-me-pricing {
  background-image: url("/assets/images/rule.png");
  background-repeat: no-repeat;
}

.block-work-with-me-details {
  background-position: left top;
  padding: 1.3333333333rem 0;
  font-family: "Apercu";
}
.block-work-with-me-details p:last-of-type {
  margin-bottom: 0;
}

.block-work-with-me-pricing {
  background-position: left top;
  padding: 1.3333333333rem 0 0 0;
  font-style: italic;
}
.block-work-with-me-pricing p:last-of-type {
  margin-bottom: 0;
}

.block-archive-list {
  margin-bottom: 5.5555555556rem;
}
@media (max-width: 800px) {
  .block-archive-list {
    padding: 0 1.3888888889rem;
  }
}
@media (min-width: 1080px) {
  .block-archive-list {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.3333333333rem;
  }
}
.block-archive-list .h2,
.block-archive-list h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}
.block-archive-list a {
  color: #0875c2;
}
.block-archive-list a:hover {
  text-decoration: underline;
}
.block-archive-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.block-essay-list {
  margin-bottom: 5.5555555556rem;
}
@media (max-width: 800px) {
  .block-essay-list {
    padding: 0 1.3888888889rem;
  }
}
@media (min-width: 1080px) {
  .block-essay-list {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.3333333333rem;
  }
}
.block-essay-list .essay-link {
  display: inline-block;
  margin-bottom: 15px;
  font-family: "Apercu", Arial, sans-serif;
  font-weight: 700;
}
.block-essay-list .h2,
.block-essay-list h2 {
  font-size: 1.7777777778rem;
  line-height: 1.243125;
  margin-top: 0rem;
  margin-bottom: 1.3333333333rem;
}
.block-essay-list a {
  color: #0875c2;
}
.block-essay-list a:hover {
  text-decoration: underline;
}
.block-essay-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #aeaeae;
  font-size: 1rem;
  line-height: 1.5555555556;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.block-essay-list ul li {
  padding: 0.8333333333rem 0;
  border-bottom: 1px solid #aeaeae;
}
.block-essay-list ul li p:last-of-type {
  margin-bottom: 0;
}

.block-the-book {
  padding: 3.3333333333rem 0;
  margin-top: 5.5555555556rem;
  margin-bottom: 6.6666666667rem;
  background-color: #f4f7ff;
}
.block-the-book .h2,
.block-the-book h2 {
  font-size: 1.7777777778rem;
  line-height: 1.5;
  margin-top: 0rem;
  margin-bottom: 2.2222222222rem;
  font-weight: 700;
}

.block-the-book-inner {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.3888888889rem;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 3.1666666667rem;
  row-gap: 2.2222222222rem;
  grid-template-areas: "tbh tbh tbh" "tbc tbc tbc" "tbi tbi tbi";
}
.block-the-book-inner:after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 800px) {
  .block-the-book-inner {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    column-gap: 3.1666666667rem;
    row-gap: 0rem;
    grid-template-areas: "tbh tbh tbh" "tbc tbi tbi";
  }
}
@media (max-width: 800px) {
  .block-the-book-inner {
    padding: 0 1.3888888889rem;
  }
}

.block-the-book-header {
  grid-area: tbh;
}

.block-the-book-content {
  grid-area: tbc;
}
.block-the-book-content p:first-of-type {
  color: #0875c2;
  font-weight: 700;
  font-family: "Apercu", Arial, sans-serif;
}
.block-the-book-content p:first-of-type a {
  color: #0875c2;
}

.block-the-book-image {
  grid-area: tbi;
}

.block-the-book-image-wrap {
  background-color: #b8d9c6;
  width: 100%;
  height: 100%;
  padding: 1.4444444444rem;
  display: grid;
  justify-content: center;
  align-items: center;
}
.block-the-book-image-wrap img {
  max-width: 14.1111111111rem;
}

.featureimage {
  background: linear-gradient(31.21deg, rgba(8, 129, 171, 0.1) 42.77%, rgba(230, 182, 110, 0.1) 86.48%);
  border: 1px solid #aeaeae;
  max-width: 100vw;
  margin-top: 48px;
  margin-bottom: 48px;
  line-height: 0;
}
@media (min-width: 800px) {
  .featureimage {
    max-width: 1120px;
    margin: auto calc(50% - 50vw + 52px);
    transform: translateX(calc(50vw - 50% - 52px));
    margin-top: 5.5555555556rem;
    margin-bottom: 2.6666666667rem;
  }
}
.featureimage figure {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}
.featureimage figure.contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.featureimage figure.cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.block-projectlist {
  margin-bottom: 2.2222222222rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  row-gap: 2.6666666667rem;
  column-gap: 2.6666666667rem;
}
@media (max-width: 800px) {
  .block-projectlist {
    padding: 0 1.3888888889rem;
  }
}
.block-projectlist a {
  color: #0875c2;
  font-weight: 700;
  font-size: 1.3333333333rem;
  line-height: 1.2083333333;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Apercu", Arial, sans-serif;
}
.block-projectlist a:hover {
  text-decoration: underline;
}
.block-projectlist figure a {
  display: block;
  line-height: 0;
  width: 100%;
  height: 100%;
}
.block-projectlist p {
  font-size: 1rem;
  line-height: 1.2222222222;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Apercu", Arial, sans-serif;
}
@media (max-width: 800px) {
  .block-projectlist {
    grid-template-columns: 1fr;
  }
}
.block-projectlist .project.first-project {
  grid-column: span 2;
}
@media (max-width: 800px) {
  .block-projectlist .project.first-project {
    grid-column: span 1;
  }
}
.block-projectlist .project {
  grid-column: span 1;
}
.block-projectlist .older-project figure {
  background: #fff;
  border: 1px solid #aeaeae;
  margin-bottom: 16px;
  background: #fff;
  background: radial-gradient(62.5% 105.93% at 19.25% 62.29%, rgba(255, 233, 132, 0.2) 0%, rgba(238, 238, 238, 0.112) 100%), radial-gradient(73.88% 212.22% at 17.25% 23.31%, #eeeeee 0%, rgba(215, 228, 218, 0.72) 100%);
}
.block-projectlist .older-project figure.contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.block-projectlist .older-project figure.cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.first-project-inner {
  border: 1px solid #aeaeae;
  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 0;
}
.first-project-inner figure {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  background: #fff;
  background: radial-gradient(62.5% 105.93% at 19.25% 62.29%, rgba(255, 233, 132, 0.2) 0%, rgba(238, 238, 238, 0.112) 100%), radial-gradient(73.88% 212.22% at 17.25% 23.31%, #eeeeee 0%, rgba(215, 228, 218, 0.72) 100%);
}
.first-project-inner figure.contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.first-project-inner figure.cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.block-CTA {
  text-align: center;
  margin-bottom: 6.9444444444rem;
}
.block-CTA p {
  font-size: 1.3333333333rem;
  line-height: 1.2083333333;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.recent-projects {
  background-color: #f4f7ff;
  padding-top: 6.6666666667rem;
}
@media (max-width: 800px) {
  .recent-projects h2 {
    padding: 0 1.3888888889rem;
  }
}
.recent-projects .arrow-butt {
  margin-bottom: 6.6666666667rem;
}

.item-grid {
  margin-bottom: 3.6111111111rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 2.2222222222rem;
  grid-column-gap: 3.1666666667rem;
}
@media (max-width: 800px) {
  .item-grid {
    margin-bottom: 2.6666666667rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0 1.3888888889rem;
  }
}
.item-grid figcaption {
  padding: 0.4444444444rem 0;
  line-height: 1.5;
  font-family: "Apercu";
  text-align: left;
  font-style: normal;
}
.item-grid figcaption a {
  color: #0875c2;
  font-size: 1.3333333333rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.6666666667rem;
  white-space: nowrap;
}
.item-grid p {
  font-size: 1rem;
  line-height: 1.2222222222;
  margin-top: 0;
  margin-bottom: 0;
}
.item-grid a {
  width: 100%;
  line-height: 0;
}

.item-container {
  display: block;
}
.item-container img.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item {
  grid-column: span 1;
}
.item:first-child {
  grid-column: span 2;
  grid-row: span 2;
}
@media (max-width: 800px) {
  .item:first-child {
    grid-column: span 1;
  }
}
.item figure {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}
.item figure .item-image {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #aeaeae;
  background-color: #fff;
  background: radial-gradient(62.5% 105.93% at 19.25% 62.29%, rgba(255, 233, 132, 0.2) 0%, rgba(238, 238, 238, 0.112) 100%), radial-gradient(73.88% 212.22% at 17.25% 23.31%, #eeeeee 0%, rgba(215, 228, 218, 0.72) 100%);
}
@media (max-width: 965px) {
  .item figure {
    grid-template-rows: 1fr auto;
  }
}