// ======================================================================
// 99-blocks - First Project
// ======================================================================

.block-projectlist {
  margin-bottom: rem(40px);

  @include media("<medium") {
    padding: 0 rem(25px);
  }

  a {
    color: $cerulean;
    font-weight: 700;
    @include type-free(24px, 0px, 0px, 29px, true);
    font-family: $apercu;
    &:hover {
      text-decoration: underline;
    }
  }

  figure a {
    display: block;
    line-height: 0;
    width: 100%;
    height: 100%;
  }

  p {
    // @include type-modular(zeta, 0px, 24px);
    @include type-free(18px, 0, 0, 22px, true);
    font-family: $apercu;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;

  @include media("<medium") {
    grid-template-columns: 1fr;
  }

  grid-template-rows: 1fr;
  row-gap: rem(48px);
  column-gap: rem(48px);

  .project.first-project {
    grid-column: span 2;
    @include media("<medium") {
      grid-column: span 1;
    }
  }
  .project {
    grid-column: span 1;
  }

  .older-project {
    figure {
      background: #fff;
      border: 1px solid #aeaeae;
      margin-bottom: 16px;

      background: #fff;

      background: radial-gradient(
            62.5% 105.93% at 19.25% 62.29%,
            rgba(255, 233, 132, 0.2) 0%,
            rgba(238, 238, 238, 0.112) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
            73.88% 212.22% at 17.25% 23.31%,
            #eeeeee 0%,
            rgba(215, 228, 218, 0.72) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

          &.contain {
            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
              display: block;
            }
          }
      
      
          &.cover {
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              display: block;
            }
          }
    }
  }
}

.first-project-inner {
  border: 1px solid #aeaeae;

  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 0;

  // padding: rem(66px) rem(25px) rem(90px);
  // padding: 0 rem(25px);

  figure {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    background: #fff;

    background: radial-gradient(
          62.5% 105.93% at 19.25% 62.29%,
          rgba(255, 233, 132, 0.2) 0%,
          rgba(238, 238, 238, 0.112) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          73.88% 212.22% at 17.25% 23.31%,
          #eeeeee 0%,
          rgba(215, 228, 218, 0.72) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

    &.contain {
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    &.cover {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
