// ======================================================================
// 99-blocks - About
// ======================================================================

.block-about {
  padding: rem(60px) 0 rem(60px) 0;

  margin-top: rem(100px);
  a {
    // font-style: italic;
    color: $cerulean;
    &:hover {
      text-decoration: underline;
    }
  }
  .h1,
  h1 {
    // @include type-modular(delta, 0px, 48px);
    @include type-free(40px, 0px, 24px, 49.72px, true);
    font-weight: 700;
  }
}

.block-about-inner {
  display: grid;
  grid-template-columns: rem(532px) 1fr;

  grid-template-rows: auto auto;
  grid-template-areas:
    "bh bh"
    "bc bi";

  @include media("<960px") {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    column-gap: 0;
    grid-template-areas:
      "bh bh"
      "bi bi"
      "bc bc";
  }

  @include media(">1485px") {
    padding: 0 0 0 rem(145px);
    column-gap: rem(62px);
  }
  @include media("<1485px") {
    grid-template-columns: 60% 40%;

    @include container;
    padding: 0 rem(60px);
  }

  @include media("<medium") {
    @include container;
    padding: 0 rem(25px);
  }

  @include media(">960px", "<1485px") {
    column-gap: rem(31px);
  }

  .block-header {
    grid-area: bh;
  }

  .block-content {
    grid-area: bc;
  }

  .block-image {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-area: bi;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    figure {
      width: 100%;
      margin-bottom: rem(24px);
    }
  }
}
