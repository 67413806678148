// ======================================================================
// 99-blocks - Newsletter
// ======================================================================

.newsletter {
  padding: rem(72px) rem(25px);

  @include media(">medium") {
    padding: rem(120px) rem(25px);
  }
  h2 {
    @include type-free(32px, 0px, 24px, 39.78px, true);
  }
  p {
    margin-bottom: rem(40px);
  }
  main {
    @include container(728px, true, 0);
  }
}
