// ======================================================================
// 06-components - Menu
// ======================================================================

.menu {
  @include container;
  padding: 0 rem(25px);

  @include media("<medium") {
    padding: rem(21px) rem(25px) 0 rem(25px);
  }

  font-family: $apercu;
}

.menu-toggle {
  @include media(">medium") {
    display: none;
  }
}

.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  z-index: 200;
  position: relative;
  @include media(">medium") {
    margin: rem(-14px) 0 0 0;
  }

  li {
    flex-grow: 1;
    text-align: right;
    &.active a {
      color: $tomato;
    }
  }

  .home-link {
    flex-grow: 0;
    text-align: left;
  }

  a {
    @include type-free(20px, 0px, 0px, 24px, true);
    text-transform: uppercase;
    display: inline;
    padding: 0 0 rem(2.25px);
    text-decoration: none;
    letter-spacing: 1px;
    color: $bark;
    &:hover {
      color: $tomato;
    }
  }
}

li.menu-link {
  @include media("<medium") {
    display: none;
  }
}

.menu-open {
  li.menu-link,
  li.home-link,
  .logo a {
    display: none;
  }
}

.site-menu {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: $white;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  opacity: 0;
  li.menu-link {
    display: block;
  }
  &.site-menu-inactive {
    // animation: menufadeout 0.4s;
    opacity: 0;
  }

  &.site-menu-active {
    display: grid;
    // animation: menufadein 0.4s;
    opacity: 1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: rem(96px) 0 0 rem(25px);
  }

  a {
    @include type-free(17px, 0, 32px, 21px, true);
    text-transform: uppercase;

    font-family: $apercu;
    display: inline-block;
    color: $bark;

    &:hover {
      text-decoration: none;
      color: $tomato;
    }

    &:active,
    &:focus {
      outline: none;
      color: $tomato;
    }
  }
}

// @keyframes menufadeout {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }

// @keyframes menufadein {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
