// ======================================================================
// 04-elements - Buttons
// ======================================================================

.btn {
  @include button;
  box-sizing: border-box;
}

.arrow-butt {
  color: $tomato;
  @include type-free(24px, 0px, 0px, 29px, true);
  text-decoration: underline;
  position: relative;
  display: inline-block;
  font-family: $tiempos;

  &:hover {
    color: $peach;
    text-decoration: underline;
    &:after {
      background-image: url("/assets/images/arrow-peach.svg");
    }
  }

  &:after {
    content: "";
    width: 32px;
    height: 32px;
    background-image: url("/assets/images/arrow-tomato.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    right: -44px;
    top: 0;
  }
}

button.arrow-butt {
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

.arrow-butt-blue-left,
.arrow-butt-blue-right {
  color: $cerulean;
  @include type-free(18px, 0px, 0px, 24px, true);
  font-family: "Apercu";
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:hover {
    text-decoration: underline;
    color: $cerulean;
  }

  &:after,
  &:before {
    content: "";
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    top: 0;
  }
}

.arrow-butt-blue-right {
  margin-right: 44px;
  &:after {
    background-image: url("/assets/images/right-blue-arrow.svg");
    right: -44px;
  }
}

.arrow-butt-blue-left {
  margin-left: 44px;
  &:before {
    background-image: url("/assets/images/left-blue-arrow.svg");
    left: -44px;
  }
}

.menu-butt {
  background: transparent;
  padding: 0;
  border: none;
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
  }

  .cross-icon {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.menu-open {
  .menu-icon {
    display: none;
  }
  .cross-icon {
    display: block;
  }
}
