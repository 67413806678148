// ======================================================================
// 04-elements - Table
// ======================================================================

table {
  //   @include table();
  width: 100%;
  max-width: 1120px;

}
