// ======================================================================
// 99-blocks - What i do
// ======================================================================

.block-what-i-do {
  @include container;
  padding: 0 0 rem(120px) 0;
  @include media("<medium") {
    padding: rem(40px) rem(25px);
  }
  .h2,
  h2 {
    // @include type-modular(delta, 0px, 48px);
    @include type-free(32px, 0px, 40px, 40px, true);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @include media(">medium") {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: rem(56px);
    }

    @include type-free(18px, 0px, 0px, 28px, true);
  }
}
