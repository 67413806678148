// ======================================================================
// 99-blocks - Quote
// ======================================================================

.block-qoute {
  margin-bottom: rem(100px);
}

.block-qoute-inner {
  @include container;

  padding: rem(120px) 0;
  text-align: center;
  font-family: $tiempos;
  blockquote {
    @include type-free(32px, 0px, 0px, 40px, true);

    @include media("<medium") {
      @include type-free(24px, 0px, 0px, 29px, true);
    }
    font-family: $tiempos;
  }

  footer {
    margin-top: rem(24px);
    font-family: $apercu;
    font-style: normal;
    @include type-free(15px, 24px, 0px, 19px, true);

    @include media("<medium") {
      @include type-free(15px, 24px, 0px, 18px, true);
    }
  }
}
