// ======================================================================
// 99-blocks - Work with me
// ======================================================================

.wwm {
  background-color: $deepblue;
  padding: rem(60px) 0;

  @include media(">medium") {
    padding: rem(120px) 0;
  }
  h2,
  p {
    color: $white;
  }
  h2 {
    @include type-free(32px, 0px, 40px, 39.78px, true);
  }
}

.wwm-content {
  @include media(">medium") {
    display: grid;
    grid-template-columns: 1fr 111px;
    grid-template-rows: auto auto;
    column-gap: rem(24px);
    grid-template-areas:
      "wwml wwml wwmr"
      "wwml wwml wwmr";
  }
}

.wwm-left {
  grid-area: wwml;
  p {
    max-width: rem(725px);
  }
}
.wwm-right {
  grid-area: wwmr;
  img {
    max-width: rem(111px);
    display: block;
    @include media("<medium") {
      margin: rem(58px) 0 0 0;
    }
  }
  @include media(">medium") {
    display: grid;
    align-items: center;
    justify-content: flex-end;
  }
}
