// ======================================================================
// 99-blocks - The Book
// ======================================================================

.block-the-book {
  padding: rem(60px) 0;
  margin-top: rem(100px);
  margin-bottom: rem(120px);
  background-color: $periwinkle;

  .h2,
  h2 {
    // @include type-modular(delta, 0px, 48px);
    @include type-free(32px, 0px, 40px, 48px, true);
    font-weight: 700;
  }
}

.block-the-book-inner {
  @include container();
  padding: 0 rem(25px);
  // @include media("<1269px") {
  //   padding: 0 rem(60px);
  // }

  display: grid;

  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  column-gap: rem(57px);
  row-gap: rem(40px);
  grid-template-areas:
    "tbh tbh tbh"
    "tbc tbc tbc"
    "tbi tbi tbi";

  @include media(">medium") {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    column-gap: rem(57px);
    row-gap: rem(0px);
    grid-template-areas:
      "tbh tbh tbh"
      "tbc tbi tbi";
  }

  @include media("<medium") {
    padding: 0 rem(25px);
  }
}

.block-the-book-header {
  grid-area: tbh;
}
.block-the-book-content {
  grid-area: tbc;

  p:first-of-type {
    color: $cerulean;
    font-weight: 700;
    font-family: $apercu;

    a {
      color: $cerulean;
    }
  }
}

.block-the-book-image {
  grid-area: tbi;
}

.block-the-book-image-wrap {
  background-color: $seafoam;
  width: 100%;
  height: 100%;
  padding: rem(26px);
  display: grid;
  justify-content: center;
  align-items: center;

  img {
    max-width: rem(254px);
  }
}
