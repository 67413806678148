// ======================================================================
// 99-blocks - Archives
// ======================================================================

.block-archive-list {
  margin-bottom: rem(100px);
  @include media("<medium") {
    padding: 0 rem(25px);
  }
  @include media(">1080px") {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: rem(24px);
  }

  .h2,
  h2 {
    @include type-free(32px, 0px, 24px, 39.78px, true);
  }

  a {
    color: $cerulean;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @include type-free(18px, 0px, 0px, 28px, true);
  }
}
